import Vue from 'vue'
import App from './App.vue'
import router from './router'
import NutUI from '@nutui/nutui'
import '@nutui/nutui/dist/nutui.css'
import enUS from '@nutui/nutui/dist/locales/lang/en-US'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import 'mint-ui/lib/style.css'
import Mint from 'mint-ui';
Vue.use(Mint)

NutUI.install(Vue)
Vue.use(NutUI, {ocale: 'en-US', lang: enUS})
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
