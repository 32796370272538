<template>
  <!-- 个人认证 -->
  <div class="personal-index wrapper">
    <div class="personal-centent public-item">
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Full name</p>
        <nut-textinput  v-model="personalInfo.full_name" placeholder="Please enter your full name" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Passport no</p>
        <nut-textinput  v-model="personalInfo.passport_no" placeholder="Please enter your passport no." :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Gender</p>
        <div class="list-selected" @click="genderShow = true">
          <span class="title"><label v-if="genderText === ''">Please select your gender</label><label>{{genderText}}</label></span>
          <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
        </div>
        <nut-picker :is-visible="genderShow" :list-data="genderList" @confirm="genderConfirm" @close="nationalityShow = false"></nut-picker>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Date of birth</p>
        <div class="list-selected" @click="birthShow = true">
          <span class="title"><label v-if="birthText === ''">Please select your date of birth</label><label>{{birthText}}</label></span>
          <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
        </div>
        <nut-datepicker :is-visible="birthShow" title="Please select your date of birth" type="date" dateformat="yyyy-mm-dd" startDate="1981-01-01" :is-show-chinese="false" @close="birthShow = false" @choose="birthChoose"></nut-datepicker>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Nationality</p>
        <div class="list-selected" @click="nationalityShow = true">
          <span class="title"><label v-if="nationalityText === ''">Please select</label><label>{{nationalityText}}</label></span>
          <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
        </div>
        <nut-picker :is-visible="nationalityShow" :list-data="nationalityList" @confirm="nationalityConfirm" @close="nationalityShow = false"></nut-picker>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Phone</p>
        <nut-textinput  v-model="personalInfo.mobile" placeholder="Please enter your Phone" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Email</p>
        <nut-textinput  v-model="personalInfo.email" placeholder="Please enter your Email" :clearBtn="false" :disabled="true" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>In China now?</p>
        <div class="list-selected" @click="inChinaShow = true">
          <span class="title"><label v-if="inChinaText === ''">Please select whether you are in China now.</label><label>{{inChinaText}}</label></span>
          <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
        </div>
        <nut-picker :is-visible="inChinaShow" :list-data="inChina" @confirm="inChinaConfirm" @close="inChinaShow = false"></nut-picker>
      </div>
      <div class="item-list" v-if="personalInfo.live_in_china === '1'">
        <p class="list-title"><span class="title-icon">*</span>Upload residence permit</p>
        <div class="list-imgs">
          <nut-uploader
            name="file"
            url="https://center.istudyedu.com/api/agents/upload"
            :headers="importHeaders"
            :isPreview="true"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
            @success="permitSuccess"
            @preview="permitPreview"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <img v-if="permitImg !== ''" class="upload-img" :src="permitImg" />
            <span v-else class="upload-btn">+</span>
          </nut-uploader> 
        </div>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Upload passport</p>
        <div class="list-imgs">
          <nut-uploader
            name="file"
            url="https://center.istudyedu.com/api/agents/upload"
            :headers="importHeaders"
            :isPreview="true"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
            @success="passportSuccess"
            @preview="passportPreview"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <img v-if="passportImg !== ''" class="upload-img" :src="passportImg" />
            <span v-else class="upload-btn">+</span>
          </nut-uploader> 
        </div>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Upload picture that you hold your passport</p>
        <div class="list-imgs">
          <nut-uploader
            name="file"
            url="https://center.istudyedu.com/api/agents/upload"
            :headers="importHeaders"
            :isPreview="true"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
            @success="pictureSuccess"
            @preview="picturePreview"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <img v-if="pictureImg !== ''" class="upload-img" :src="pictureImg" />
            <span v-else class="upload-btn">+</span>
          </nut-uploader> 
        </div>
      </div>
      <div class="item-list">
        <p class="list-title">WeChat ID</p>
        <nut-textinput  v-model="personalInfo.wechat" placeholder="Please enter your WeChat ID" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title">Facebook ID</p>
        <nut-textinput  v-model="personalInfo.facebook" placeholder="Please enter your Facebook ID" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title">WhatsApp ID</p>
        <nut-textinput  v-model="personalInfo.whatsapp" placeholder="Please enter your WhatsApp ID" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title">Residential address</p>
        <nut-textinput  v-model="personalInfo.residence" placeholder="Please enter your residential address" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
    </div>
    <div class="personal-bottom">
      <div class="bottom-box">
        <div class="box-checkbox">
          <nut-checkbox v-model="checked" :animation="false" size="small"> I confirm my information is real and correct.</nut-checkbox>
        </div>
        <div class="bottom-btn" @click="personalVerified">Submit</div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'personalIndex',
  props: {},
  data () {
    return {
      checked: true,
      statu: '',
      role: 0,
      importHeaders: {
        Authorization: 'bearer ' + window.localStorage.getItem('token')
      },
      personalInfo: {
        full_name: '',
        passport_no: '',
        gender: 2,
        wechat: '',
        birthday: '',
        facebook: '',
        country_id: JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')).country_id : '',
        whatsapp: '',
        mobile: JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')).mobile : '',
        residence: '',
        email: JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')).email : '',
        live_in_china: 0,
        residence_attachment: '',
        passport_attachment: '',
        passport_attachment2: ''
      },
      genderText: '',
      genderShow: false,
      genderList: [[
        {
          label: '2',
          value: 'Please select'
        },{
          label: '0',
          value: 'Female'
        },{
          label: '1',
          value: 'Male'
        },{
          label: '3',
          value: 'Others'
        }
      ]],
      birthShow: false,
      birthText: '',
      nationalityShow: false,
      nationalityText: '',
      nationalityList: [
        [
          {
            label: 0,
            value: "Select student's nationality"
          }
        ]
      ],
      inChinaShow: false,
      inChinaText: '',
      inChina: [
        [
          {
            label: '2',
            value: 'Please select'
          },
          {
            label: '0',
            value: 'No'
          },
          {
            label: '1',
            value: 'Yes'
          }
        ]
      ],
      permitImg: '',
      passportImg: '',
      pictureImg: ''
    }
  },
  components: {},
  computed: {},
  created () {
    this.getCountries()
  },
  watch: {},
  methods: {
    getInfo () {
      let that = this
      Api.post({
        url: "users/info?include=agent",
        success (res) {
          if (res.data && res.code === 200) {
            let datas = res.data.agent.data
            that.statu = Number(datas.auth_state)
            that.role = Number(res.data.role)
            if (datas.role === 1) {
              that.personalInfo.full_name = datas.full_name
              that.personalInfo.country_id = datas.country_id
              that.personalInfo.email = datas.email
              that.personalInfo.facebook = datas.facebook
              that.personalInfo.gender = datas.gender
              that.personalInfo.live_in_china = datas.live_in_china
              that.personalInfo.mobile = datas.mobile
              that.personalInfo.passport_no = datas.passport_no
              that.personalInfo.wechat = datas.wechat
              that.personalInfo.whatsapp = datas.whatsapp
              that.personalInfo.birthday = datas.birthday
              that.personalInfo.residence = datas.birthday
              that.personalInfo.residence_attachment = datas.residence_attachment ? datas.residence_attachment.substring(datas.residence_attachment.indexOf('agent')) : ''
              that.personalInfo.passport_attachment = datas.passport_attachment ? datas.passport_attachment.substring(datas.passport_attachment.indexOf('agent')) : ''
              that.personalInfo.passport_attachment2 =  datas.passport_attachment2 ? datas.passport_attachment2.substring(datas.passport_attachment2.indexOf('agent')) : ''
            }
          }
        }
      })
    },
    // 选择gender回调
    genderConfirm (e) {
      this.genderShow = false
      this.personalInfo.gender = e[0].label
      this.genderText = e[0].value
    },
    // 选择日期回调
    birthChoose (e) {
      this.personalInfo.birthday = e[3]
      this.birthText = e[3]
      this.birthShow = false
    },
    nationalityConfirm (e) {
      this.nationalityShow = false
      this.personalInfo.country_id = e[0].label
      this.nationalityText = e[0].value
    },
    inChinaConfirm (e) {
      this.inChinaShow = false
      this.personalInfo.live_in_china = e[0].label
      this.inChinaText = e[0].value 
    },
    // residence permit上传成功
    permitSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        this.$toast.success('Uploaded successfully')
        this.personalInfo.residence_attachment = data.data.url ? data.data.url : ''
      }
    },
    permitFail () {
      this.$toast.fail('upload failed')
    },
    permitPreview (e) {
      this.permitImg = e
    },
    passportSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        this.$toast.success('Uploaded successfully')
        this.personalInfo.passport_attachment = data.data.url ? data.data.url : ''
      }
    },
    passportPreview  (e) {
      this.passportImg = e
    },
    pictureSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        this.$toast.success('Uploaded successfully')
        this.personalInfo.passport_attachment2 = data.data.url ? data.data.url : ''
      }
    },
    picturePreview (e) {
      this.pictureImg = e
    },
    // 获取国家
    getCountries () {
      let that = this
      Api.get({
        url: 'countries',
        data: {},
        success (res) {
          if (Number(res.code) === 200 && res.data) {
            for (let i in res.data) {
              let data = {
                label: res.data[i].id,
                value: res.data[i].name
              }
              that.nationalityList[0].push(data)
            }
          }
        }
      })
    },
    // 个人认证 字段验证
    personalInfoVerify () {
      // this.personalInfo.birthday = this.moment(this.personalInfo.birthday).format('YYYY-MM-DD')
      if (this.personalInfo.full_name === '') {
        this.$toast.warn('Please enter your full name.')
        return false
      }
      if (this.personalInfo.birthday === '') {
        this.$toast.warn('please enter date of birth')
        return false
      }
      if (Number(this.personalInfo.country_id) === 0) {
        this.$toast.warn('Please select your nationality.')
        return false
      }
      if (this.personalInfo.mobile === '') {
        this.$toast.warn('Please enter your phone no.')
        return false
      }
      if (this.personalInfo.email === '') {
        this.$toast.warn('Please enter your email address.')
        return false
      }
      if (this.personalInfo.passport_no === '') {
        this.$toast.warn('Please enter your passport no.')
        return false
      }
      if (this.personalInfo.passport_attachment === '') {
        this.$toast.warn('Please upload the scanned copy of your passport.')
        return false
      }
      if (this.personalInfo.passport_attachment2 === '') {
        this.$toast.warn('Please upload the picture that you hold your passport.')
        return false
      }
      return true
    },
    // 个人认证
    personalVerified () {
      let that = this
      if (!this.checked) {
        this.$toast.warn('Please tick the confirmation message.')
        return false
      }
      if (!this.personalInfoVerify()) {
        return false
      }
      Api.post({
        url: 'agents/personalVerified',
        data: this.personalInfo,
        success (res) {
          if (res.code === 200 && res.data) {
            that.$toast.success(res.data.msg)
            that.$router.push({path: '/list/goodsList'})
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.personal-index>>>.nut-checkbox input:checked{background-color:#b0332f;border-color:#b0332f;}
.personal-centent{background-color:#fff;padding:0 10px;box-sizing:border-box;}
.personal-bottom{height:100px;}
.box-checkbox{padding-top:15px;margin:0 auto;width:280px;}
.personal-bottom .bottom-box{position:fixed;bottom:0;height:100px;max-width:750px;width:100%;margin:0 auto;background-color:#fff;}
.personal-bottom .bottom-box .bottom-btn{margin:10px 20px;height:45px;line-height:45px;text-align:center;background-color:#b0332f;color:#fff;border-radius:10px;}
.personal-bottom .bottom-text{text-align: center;margin-bottom:20px;color: #564363;font-size:14px;}
</style>
