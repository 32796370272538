<template>
  <div class="footerNav-index">
    <ul class="nav-item">
      <li class="item-list" :class="{'active': type === item.type}" v-for="(item, index) in list" :key="index" @click="goPage(item.url)">
        <i class="list-icon" :class="item.type"></i>
        <p class="list-text">{{ item.title }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'footerNav',
  props: {
    type: {
      type: String,
      default: 'home'
    }
  },
  data () {
    return {
      list: [
        {
          title: 'Scholarships',
          type: 'home',
          url: '/list/goodsList'
        },
        {
          title: 'Universities',
          type: 'apply',
          url: '/list/universitiesList'
        },
        {
          title: 'Order',
          type: 'order',
          url: '/order'
        }
      ]
    }
  },
  components: {},
  computed: {},
  created () {},
  watch: {},
  methods: {
    goPage (url) {
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style scoped>
.footerNav-index{height:65px;}
.nav-item{position:fixed;bottom:0;width:100%;max-width:750px;margin:0 auto;background-color:#fff;border-top:1px solid #e6e6e6;overflow:hidden;height:55px;}
.nav-item .item-list{float:left;width:33.33%;text-align:center;font-size:12px;color:#ccc;}
.nav-item .item-list.active{color:#b0332f;}
.nav-item .item-list .list-icon{display: block;width:25px;height:25px;margin:5px auto;background:url('~@/assets/images/nav-home.png') center no-repeat;background-size:25px;}
.nav-item .item-list.active .list-icon.home{background-image:url('~@/assets/images/nav-home-active.png');}
.nav-item .item-list .list-icon.apply{background-image:url('~@/assets/images/nav-apply.png');}
.nav-item .item-list.active .list-icon.apply{background-image:url('~@/assets/images/nav-apply-active.png');}
.nav-item .item-list .list-icon.order{background-image:url('~@/assets/images/nav-order.png');}
.nav-item .item-list.active .list-icon.order{background-image:url('~@/assets/images/nav-order-active.png');}
</style>
