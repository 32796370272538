<template>
  <div class="goods-public">
    <ul class="goods-item">
      <li class="goods-item-list" v-for="(item, index) in list" :key="index">
        <nut-leftslip>
          <div slot="slip-main" class="slip-main">
            <div class="list-box">
              <div class="list-img">
                <img class="img-self" :src="item.scg_cover_image" />
                <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
              </div>
              <div class="list-info">
                <p class="info-title">{{item.en_name}}</p>
                <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span>({{item.is_self === 1 ? item.tuition_last_fee_unit : item.self_tuition_fee_unit}})</p>
                <p class="info-text">City: {{item.city}} {{item.province}}</p>
                <p class="info-text">Degree: {{item.education}}</p>
                <p class="info-text">Teaching language: {{item.language}}</p>
              </div>
            </div>
          </div>
          <div slot="slipbtns" class="slipbtns"><a href="javascript:;" @click="deleteSelect(item.id)">Delete</a></div>
        </nut-leftslip>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'overGoodsList',
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
    }
  },
  components: {},
  computed: {},
  created () {},
  watch: {},
  methods: {
    deleteSelect (id) {
      this.$emit('deleteSelect', id)
    }
  }
}
</script>

<style scoped>
.goods-item .goods-item-list{overflow:hidden;padding:10px 0;border-bottom:4px solid #b0332fa6;}
.goods-item .goods-item-list .list-img{float:left;position:relative;}
.goods-item .goods-item-list .list-img .img-self{display: block;width:120px;height:120px;}
.goods-item .goods-item-list .list-img .goods-issp{position:absolute;padding:2px 6px;left:0;top:0;font-size:12px;background-color:#b0332f70;color:#fff;border-radius:0 12px 0 12px;}
.goods-item .goods-item-list .list-img .goods-id{position:absolute;left:0;bottom:0;padding:0 5px;font-size:12px;text-align:center;background-color:#b0332fa6;width:100%;line-height:22px;color:#fff;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;box-sizing:border-box;}
.goods-item .goods-item-list .list-info{margin-left:140px;}
.goods-item .goods-item-list .list-info .info-title{font-size:12px;color:#a1a1a1;line-height:18px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-break: break-all;margin-bottom:5px;}
.goods-item .goods-item-list .list-info .info-text{font-size:14px;color:#737373;margin-bottom:5px;}
.goods-item .goods-item-list .list-info .info-text .text-color{color:#ff6433;}
.goods-item .goods-item-list .list-info .info-text:last-child{margin-bottom:0;}
.goods-item .goods-item-list .list-info .info-text .text-btn{width:72px;display:block;background-color:#b0332f;text-align: center;color: #fff;font-size:12px;line-height:18px;border-radius:18px;}
</style>
