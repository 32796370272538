<template>
  <div class="apply-index wrapper">
    <div class="apply-nav">
      <nut-navbar class="nav-top" @on-click-back="back" :leftShow="true" :rightShow="false">Apply</nut-navbar>
    </div>
    <ul class="index-top">
      <li class="top-list" :class="{'active': type === 'Student'}" @click="type = 'Student'">Fill in Student Info</li>
      <li class="top-list" :class="{'active': type === 'Program'}" @click="type = 'Program'">Select Program</li>
    </ul>
    <div class="index-content">
      <!-- 学生信息填写 -->
      <div class="public-item" v-if="type === 'Student'">
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Surname</p>
          <nut-textinput  v-model="form.family_name" placeholder="Enter student's surname on passport" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Given name</p>
          <nut-textinput  v-model="form.given_name" placeholder="Enter student's given name on passport" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Nationality</p>
          <div class="list-selected" @click="nationalityShow = true">
            <span class="title"><label v-if="nationalityText === ''">Please select your nationality</label><label>{{nationalityText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="nationalityShow" :list-data="nationalityList" @confirm="nationalityConfirm" @close="nationalityShow = false"></nut-picker>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Date of birth</p>
          <div class="list-selected" @click="birthIsVisible = true">
            <span class="title"><label v-if="form.birthday === ''">Please select student's date of birth.</label><label>{{form.birthday}}</label></span>
          </div>
          <nut-datepicker  :is-visible="birthIsVisible" type="date" title="Select date" :is-show-chinese="false" startDate="1970-01-01" @close="birthIsVisible = false" @choose="setBirthValue"></nut-datepicker>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Sex</p>
          <div class="list-selected" @click="genderShow = true">
            <span class="title"><label v-if="genderText === ''">Select student's sex</label><label>{{genderText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="genderShow" :list-data="genderList" @confirm="genderConfirm" @close="genderShow = false"></nut-picker>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Passport no.</p>
          <nut-textinput  v-model="form.passport_no" placeholder="Enter student's passport no." :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title">Marital Status</p>
          <div class="list-selected" @click="maritalShow = true">
            <span class="title"><label v-if="maritalText === ''">Marital status</label><label>{{maritalText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="maritalShow" :list-data="maritalList" @confirm="maritalConfirm" @close="maritalShow = false"></nut-picker>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Passport expiry date</p>
          <div class="list-selected" @click="expiredVisible = true">
            <span class="title"><label v-if="form.passport_expired_at === ''">Please select student’s passport expiry date</label><label>{{form.passport_expired_at}}</label></span>
          </div>
          <nut-datepicker :is-visible="expiredVisible" type="date" title="Select date" :is-show-chinese="false" endDate="2045-12-31" @close="expiredVisible = false" @choose="setExpiredValue"></nut-datepicker>
        </div>
        <div class="item-list">
          <p class="list-title">Religion</p>
          <div class="list-selected" @click="religionShow = true">
            <span class="title"><label v-if="religionText === ''">Select student's religion</label><label>{{religionText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="religionShow" :list-data="religionList" @confirm="religionConfirm" @close="religionShow = false"></nut-picker>
        </div>
        <div class="item-list">
          <p class="list-title">Official laguage</p>
          <div class="list-selected" @click="laguageShow = true">
            <span class="title"><label v-if="laguageText === ''">Select student's official language</label><label>{{laguageText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="laguageShow" :list-data="languageList" @confirm="laguageConfirm" @close="laguageShow = false"></nut-picker>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Highest education</p>
          <div class="list-selected" @click="educationsShow = true">
            <span class="title"><label v-if="educationsText === ''">Please enter student's Highest education.</label><label>{{educationsText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="educationsShow" :list-data="educationsList" @confirm="educationsConfirm" @close="educationsShow = false"></nut-picker>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Email</p>
          <nut-textinput  v-model="form.email" placeholder="Enter student's email" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Phone</p>
          <nut-textinput  v-model="form.mobile" placeholder="Enter student's phone no." :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title">WhatsApp/WeChat</p>
          <nut-textinput  v-model="form.whatsapp" placeholder="Enter student's WhatsApp account" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Permanent address</p>
          <nut-textinput  v-model="form.hourse_address" placeholder="Enter student's permanenet address" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Has your student ever been to China?</p>
          <div class="list-selected" @click="studentInChinaShow = true">
            <span class="title"><label v-if="studentInChinaText === ''">Please select</label><label>{{studentInChinaText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="studentInChinaShow" :list-data="studentInChinaList" @confirm="studentInChinaConfirm" @close="studentInChinaShow = false"></nut-picker>
        </div>
        <div class="item-list">
          <p class="list-title">Has your student been in China now?</p>
          <div class="list-selected" @click="liveInChinaShow = true">
            <span class="title"><label v-if="liveInChinaText === ''">Please select</label><label>{{liveInChinaText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="liveInChinaShow" :list-data="liveInChinaList" @confirm="liveInChinaConfirm" @close="liveInChinaShow = false"></nut-picker>
        </div>
        <div class="item-list" v-if="form.live_in_china === 1">
          <p class="list-title">Visa type</p>
          <div class="list-selected" @click="visaTypesShow = true">
            <span class="title"><label v-if="visaTypesText === ''">Please select</label><label>{{visaTypesText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="visaTypesShow" :list-data="visaTypesList" @confirm="visaTypesConfirm" @close="visaTypesShow = false"></nut-picker>
        </div>
        <div class="item-list" v-if="form.live_in_china === 1">
          <p class="list-title">Date of visa expiry</p>
          <div class="list-selected" @click="visaExpiry = true">
            <span class="title"><label v-if="form.visa_expired_at === ''">Please select student's visa expiry date.</label><label>{{form.visa_expired_at}}</label></span>
          </div>
          <nut-datepicker :is-visible="visaExpiry" type="date" title="Select date" :is-show-chinese="false" startDate="1970-01-01" @close="visaExpiry = false" @choose="setVisaExpired"></nut-datepicker>
        </div>
      </div>
      <div class="goods-list" v-if="type === 'Program'">
        <p class="goods-btn" @click="add">Add</p>
        <overGoods :list="list" @deleteSelect="deleteSelect"></overGoods>
      </div>
    </div>
    <div class="index-btn">
      <div class="btn-self" @click="getUserInfo">Submit</div>
    </div>
    <selectGoodsList :show="show" @close="close" @goodsCallback="goodsCallback"></selectGoodsList>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import overGoods from '@/components/apply/overGoodsList.vue'
import selectGoodsList from '@/components/apply/selectGoodsList.vue'
import { MessageBox } from 'mint-ui'
export default {
  name: 'apply-index',
  props: {},
  data () {
    return {
      id: 0,
      studentId: 0,
      orderId: 0,
      type: 'Student',
      show: false,
      product_ids: [],
      list: [],
      form: {
        family_name: '',
        given_name: '',
        passport_no: '',
        passport_expired_at: '',
        country_id: 0,
        language_id: 0,
        birthday: '',
        birthplace: '',
        gender: 9,
        mobile: '',
        is_married: 0,
        occupation: '',
        religion_id: 0,
        whatsapp: '',
        education_id: null,
        school_name: '',
        visa_type: 1,
        live_in_china: 0,
        studied_in_china: 0,
        visa_expired_at: '',
        email: '',
        hourse_address: ''
      },
      genderList: [ //性别列表
        [
          {
            label: 9,
            value: "Select student's sex"
          },
          {
            label: 0,
            value: "Female"
          },
          {
            label: 2,
            value: "Male"
          },
          {
            label: 3,
            value: "Others"
          }
        ]
      ],
      genderShow: false,
      genderText: '',
      nationalityShow: false, // 国家选择弹框
      nationalityList: [ // 国家列表
        [
          {
            label: 0,
            value: "Please select your nationality"
          }
        ]
      ],
      nationalityText: '',
      birthIsVisible: false,
      maritalShow: false, // 婚姻状态弹框
      maritalText: '',
      maritalList: [ // 婚姻列表
        [
          {
            label: 0,
            value: "Single"
          },
          {
            label: 1,
            value: "Married"
          },
          {
            label: 2,
            value: "Others"
          }
        ]
      ],
      expiredVisible: false,
      religionShow: false, // 宗教
      religionText: '',
      religionList: [ // 宗教列表
        [
          {
            label: 0,
            value: "Select student's religion"
          }
        ]
      ],
      languageList: [ // 母语列表
        [
          {
            label: 0,
            value: "Select student's official language"
          }
        ]
      ],
      laguageShow: false,
      laguageText: '',
      educationsList: [ // 学历
        [
          {
            label: 1,
            value: 'Middle School'
          },
          {
            value: 'High School',
            label: 2
          },
          {
            value: 'Associate',
            label: 3
          },
          {
            value: 'Bachelor',
            label: 4
          },
          {
            value: 'Master',
            label: 5
          },
          {
            value: 'Doctoral',
            label: 6
          },
          {
            value: 'Others',
            label: 7
          }
        ]
      ],
      educationsShow: false,
      educationsText: '',
      liveInChinaList: [ // 是否在中国生活
        [
          {
            label: 0,
            value: 'No'
          },
          {
            label: 1,
            value: 'Yes'
          }
        ]
      ],
      liveInChinaShow: false,
      liveInChinaText: '',
      studentInChinaList: [ // 是否中国学习
        [
          {
            label: 0,
            value: 'No'
          },
          {
            label: 1,
            value: 'Yes'
          }
        ]
      ],
      studentInChinaShow: false,
      studentInChinaText: '',
      visaTypesList: [[]], // 签证列表
      visaTypesShow: false,
      visaTypesText: '',
      visaExpiry: false,
      auth_state: 0, // 代理商审核状态 0:待申请,1:待审核,2:已通过,3:未通过
      user_state: 0, // 用户状态 0正常 1禁用
      agent_state: 0 // 代理商账号状态 0正常 1禁用
    }
  },
  components: {
    overGoods,
    selectGoodsList
  },
  computed: {
  },
  created () {
    // this.auth_state = JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')).auth_state : 0
    // this.user_state = JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')).user_state : 0
    // this.agent_state = JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')).agent_state : 0
    let info = JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')) : {}
    if (Object.keys(info).length <= 0) {
      this.goPage('/login')
      return false
    }
    this.attestation()
    this.id = this.$route.query.program ? this.$route.query.program : ''
    this.studentId = this.$route.query.student ? Number(this.$route.query.student) : 0
    this.orderId = this.$route.query.order ? Number(this.$route.query.order) : 0
    this.getGoodsIdList(this.id)
    this.getCountries()
    this.religions()
    this.languages()
    this.visaTypes()
  },
  watch: {},
  methods: {
    // 获取学生信息
    selectStudentInfo (id) {
      let that = this
      Api.get({
        url: 'students/' + id,
        success (res) {
          if (res.code === 200 && res.data) {
            that.student_id = res.data.id
            that.form.family_name = res.data.family_name ? res.data.family_name : ""
            that.form.given_name = res.data.given_name ? res.data.given_name : ""
            that.form.passport_no = res.data.passport_no ? res.data.passport_no : ""
            that.form.passport_expired_at = res.data.passport_expired_at ? res.data.passport_expired_at : ""
            that.form.country_id = res.data.country_id ? res.data.country_id : 0
            that.form.language_id = res.data.language_id ? res.data.language_id : 0
            that.form.birthday = res.data.birthday ? res.data.birthday : ""
            that.form.birthplace = res.data.birthplace ? res.data.birthplace : ""
            that.form.gender = Number(res.data.gender)
            that.form.mobile = res.data.mobile ? res.data.mobile : ""
            that.form.is_married = res.data.is_married ? res.data.is_married : 0
            that.form.occupation = res.data.occupation ? res.data.occupation : ""
            that.form.religion_id = res.data.religion_id ? res.data.religion_id : 0
            that.form.whatsapp = res.data.whatsapp ? res.data.whatsapp : ""
            that.form.education_id = res.data.education_id ? res.data.education_id : null
            that.form.school_name = res.data.school_name ? res.data.school_name : ""
            that.form.visa_type = Number(res.data.visa_type)
            that.form.live_in_china = res.data.live_in_china ? res.data.live_in_china : 0
            that.form.studied_in_china = res.data.studied_in_china ? res.data.studied_in_china : 0
            that.form.visa_expired_at = res.data.visa_expired_at ? res.data.visa_expired_at : ""
            that.form.email = res.data.email ? res.data.email : ""
            that.form.hourse_address = res.data.hourse_address ? res.data.hourse_address : ""
          }
        }
      })
    },
    // 获取国家
    getCountries () {
      let that = this
      Api.get({
        url: 'countries',
        data: {},
        success (res) {
          if (Number(res.code) === 200 && res.data) {
            for (let i in res.data) {
              let data = {
                label: res.data[i].id,
                value: res.data[i].name
              }
              that.nationalityList[0].push(data)
            }
          }
        }
      })
    },
    // 获取语种
    languages () {
      let that = this
      Api.get({
        url: 'languages',
        success (res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              let data = {
                label: res.data[i].id,
                value: res.data[i].name
              }
              that.languageList[0].push(data)
            }
          }
        }
      })
    },
    // 获取宗教
    religions () {
      let that = this
      Api.get({
        url: 'religions',
        success (res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              let data = {
                label: res.data[i].id,
                value: res.data[i].name
              }
              that.religionList[0].push(data)
            }
          }
        }
      })
    },
    // 签证类型
    visaTypes () {
      let that = this
      Api.get({
        url: 'visas',
        data: {},
        success (res) {
          if (res.code === 200 && res.data) {
            for (let i in res.data) {
              let data = {
                label: res.data[i].id,
                value: res.data[i].name
              }
              that.visaTypesList[0].push(data)
            }
          }
        }
      })
    },
    // 选择国家
    nationalityConfirm (e) {
      this.nationalityShow = false
      this.form.country_id = e[0].label
      this.nationalityText = e[0].value
    },
    // 获取 Date of birth
    setBirthValue (e) {
      this.form.birthday = e[3]
    },
    // 选择性别
    genderConfirm (e) {
      this.genderShow = false
      this.form.gender = e[0].label
      this.genderText = e[0].value
    },
    // 确认婚姻状态
    maritalConfirm (e) {
      this.maritalShow = false
      this.form.is_married = e[0].label
      this.maritalText = e[0].value
    },
    // 设置护照过期时间
    setExpiredValue (e) {
      this.form.passport_expired_at = e[3]
    },
    // 选择宗教
    religionConfirm (e) {
      this.religionShow = false
      this.form.religion_id = e[0].label
      this.religionText = e[0].value
    },
    // 选择母语
    laguageConfirm (e) {
      this.laguageShow = false
      this.form.language_id = e[0].label
      this.laguageText = e[0].value
    },
    // 选择学历
    educationsConfirm (e) {
      this.educationsShow = false
      this.form.education_id = e[0].label
      this.educationsText = e[0].value
    },
    // 选择是否在中国生活
    liveInChinaConfirm (e) {
      this.liveInChinaShow = false
      this.form.live_in_china = e[0].label
      this.liveInChinaText = e[0].value
    },
    // 选择是否在中国学习
    studentInChinaConfirm (e) {
      this.studentInChinaShow = false
      this.form.studied_in_china = e[0].label
      this.studentInChinaText = e[0].value
    },
    // 选择签证类型
    visaTypesConfirm (e) {
      this.visaTypesShow = false
      this.form.visa_type = e[0].label
      this.visaTypesText = e[0].value
    },
    // 选择签证到期时间
    setVisaExpired (e) {
      this.form.visa_expired_at = e[3]
    },
    // 从商品详情页下单 获取商品
    getGoodsIdList (id) {
      if (id === '') {
        return false
      }
      let that = this
      Api.get({
        url: 'products/'+ id,
        data: {},
        success (res) {
          if (res.data && Number(res.code) === 200) {
            let list = []
            list.push(res.data)
            that.goodsCallback(list)
          }
        }
      })
    },
    // 获取选择商品回调
    goodsCallback (list) {
      let that = this
      list.map(res => {
        that.list.push(res)
      })
      this.list = this.unique(this.list)
      this.product_ids = []
      for (let i in this.list) {
        this.product_ids.push(this.list[i].id)
      }
    },
    // 数组去重
    unique (arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
    },
    // 删除商品
    deleteSelect (id) {
      for (let i in this.list) {
        if (Number(id) === Number(this.list[i].id)) {
          this.list.splice(i, 1)
        }
      }
      this.product_ids = []
      for (let i in this.list) {
        this.product_ids.push(this.list[i].id)
      }
    },
    // 添加商品
    add () {
      this.show = true
    },
    // 关闭添加商品弹框
    close () {
      this.show = false
    },
    // 获取用户信息
    getUserInfo () {
      let that = this
      if (Number(this.user_state) !== 0 || Number(this.agent_state) !== 0 || Number(this.auth_state) !== 2) {
        Api.post({
          url: 'users/info',
          success (res) {
            if (Number(res.errors) === 0 && res.data) {
              window.localStorage.setItem('user_info', JSON.stringify(res.data))
              that.auth_state = res.data.auth_state ? Number(res.data.auth_state) : 0
              that.user_state = res.data.user_state ?  Number(res.data.user_state) : 0
              that.agent_state = res.data.agent_state ?  Number(res.data.agent_state) : 0
              that.submit()
            }
          }
        })
      } else {
        that.submit()
      }
    },
    // 清空信息
    emptyInfo () {
      this.form = {
        family_name: '',
        given_name: '',
        passport_no: '',
        passport_expired_at: '',
        country_id: 0,
        birthday: '',
        birthplace: '',
        gender: 9,
        mobile: '',
        is_married: 0,
        occupation: '',
        religion_id: 0,
        whatsapp: '',
        education_id: null,
        school_name: '',
        visa_type: 1,
        live_in_china: 0,
        studied_in_china: 0,
        visa_expired_at: '',
        email: '',
        hourse_address: '',
        language_id: 0
      }
    },
    // 验证信息
    verify () {
      if (this.form.family_name === '') {
        this.$toast.warn("Please enter student's surname on passport.")
        return false
      }
      if (this.form.given_name === '') {
        this.$toast.warn("Please enter student's given name on passport.")
        return false
      }
      if (Number(this.form.passport_no) === '') {
        this.$toast.warn("Please enter student's passport_no.")
        return false
      }
      if (this.form.religion_id === 0) {
        this.$toast.warn("Please enter student's religion.")
        return false
      }
      if (this.form.birthday === '') {
        this.$toast.warn("Please enter student's date of birth.")
        return false
      }
      if (this.form.mobile === '') {
        this.$toast.warn("Please enter student's mobile number.")
        return false
      }
      if (this.form.passport_expired_at === '') {
        this.$toast.warn("Please select student’s passport expiry date.")
        return false
      }
      if (this.form.hourse_address === '') {
        this.$toast.warn("Please enter student's permanent address.")
        return false
      }
      return true
    },
    // 页面跳转
    goPage (path) {
      this.$router.push({path: path})
    },
    // 提交订单
    submit () {
      let that = this
      if (this.product_ids.length < 1) {
        this.$toast.warn('You have not selected a product, please select a product !')
        return false
      }
      if (this.form.family_name !== '' || this.form.given_name !== '') {
        if (!this.verify()) {
          return false
        }
      }
      let data = {
        ...this.form,
        order_id: this.orderId,
        product_ids: this.product_ids,
        student_id: this.studentId,
        from: 1
      }
      Api.post({
        url: 'orders/store',
        data: data,
        success (res) {
          if (res.data.code === 200 && res.data) {
            that.list = []
            that.product_ids = []
            that.emptyInfo()  
            that.goPage('/order')
            that.$toast.success(res.data.msg)
          } else {
            that.$toast.warn(res.data.msg)
          }
        }
      })
    },
    // 判断是否认证
    attestation () {
      if (Number(this.user_state) !== 0) {
        this.$toast.warn('Your account is blacklisted for some reason, please contact your consultant for details.')
        return false
      }
      if (Number(this.agent_state) !== 0) {
        this.$toast.warn('Your account is blacklisted for some reason, please contact your consultant for details !')
        return false
      }
      if (Number(this.auth_state) !== 2) {
        this.dialog()
        return false
      }
    },
    dialog () {
      let that = this
      MessageBox({
        title: "Identity Authentication",
        message: "Your account has not been authenticated, which will affect you to use some functions. Please choose one account type to complete identity authentication. If any questions, please contact us (WeChat: eduprchina03) or email (service@eduprchina.com)!",
        showCancelButton: true,
        confirmButtonText: 'Individual Agent',
        cancelButtonText: 'Company Agent'
      }).then(active => {
        if (active === 'confirm') {
          that.goPage('/certification/personal')
        } else if(active === 'cancel') {
          that.goPage('/certification/company')
        }
      })
      // this.$dialog({
      //   title: "Identity Authentication",
      //   content: "Your account has not been authenticated, which will affect you to use some functions. Please choose one account type to complete identity authentication. If any questions, please contact us (WeChat: eduprchina03) or email (service@eduprchina.com)!",
      //   closeBtn: false,  //显式右上角关闭按钮
      //   closeOnClickModal: false,
      //   okBtnTxt: 'Individual Agent',
      //   cancelBtnTxt: 'Company Agent',
      //   onOkBtn () {  //确定按钮点击事件
      //     that.goPage('/certification/personal')
      //     that.close() //关闭对话框
      //   },
      //   onCancelBtn () {  //取消按钮点击事件，默认行为关闭对话框
      //     that.goPage('/certification/company')
      //     that.close()
      //   }
      // })
    },
    back () {
      this.$router.go(-1) 
    }
  }
}
</script>

<style scoped>
.apply-nav{height:50px;}
.apply-nav .nav-top{position:fixed;top:0;background-color:#fff;z-index:100;width:100%;max-width:750px;border-bottom:1px solid #e6e6e6;font-size:16px;}
.apply-index{background-color:#fff;}
.index-top{overflow:hidden;padding:10px 0;font-size:14px;color:#333;}
.index-top .top-list{float:left;width:50%;text-align:center;font-weight:600;}
.index-top .top-list.active{color:#b0332f;}
.index-content{padding:0 10px;}
.goods-list .goods-btn{background-color:#b0332f;color:#fff;padding:10px 0;border-radius:20px;text-align:center;font-weight:600;font-size:16px;}
.index-btn{height:45px;}
.index-btn .btn-self{position:fixed;width:100%;max-width:720px;text-align:center;bottom:0;margin:0 auto;line-height:45px;background-color:#b0332f;color:#fff;font-size:16px;}
</style>
