<template>
  <div class="search-index wrapper">
    <div class="search-top">
      <nut-navbar  @on-click-back="back" :leftShow="true" :rightShow="false">Search</nut-navbar>
      <div class="top-search-self">
        <nut-searchbar v-model="keyword" placeText="Program name / Program ID / City" @submit="submitSearch" :hasIcon="true" :hasTextButton="true" textInfo="Cancel" customClass="search_demo"></nut-searchbar>
      </div>
    </div>
    <div class="search-content" >
      <div class="content-hot" v-if="isShow">
        <p class="hot-title">Hot</p>
        <div class="hot-item">
          <span class="hot-tag" :class="[{'icon': index === 0}]" v-for="(item, index) in labels" :key="index" @click="clickLabels(item)">{{item.name}}</span>
        </div>
      </div>
      <nut-tab @tab-switch="tabSwitch" :line-width="20" v-else>
        <nut-tab-panel tab-title="Scholarships">
          <nut-infiniteloading @loadmore="onInfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="Loading" unload-more-txt="no more information" :threshold="200">
            <div class="goods-item">
              <div class="goods-item-list" v-for="(item, index) in list" :key="index" @click="goPage(item, 'goodsDetail')">
                <div class="list-img">
                  <img class="img-self" :src="item.scg_cover_image" />
                  <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
                </div>
                <div class="list-info">
                  <p class="info-title">{{item.en_name}}</p>
                  <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span>({{item.is_self === 1 ? item.tuition_last_fee_unit : item.self_tuition_fee_unit}})</p>
                  <p class="info-text">City: {{item.city}} {{item.province}}</p>
                  <p class="info-text">Degree: {{item.education}}</p>
                  <p class="info-text">Teaching language: {{item.language}}</p>
                  <p class="info-text"><span class="text-btn" >Apply</span></p>
                </div>
              </div>
            </div>
          </nut-infiniteloading>
        </nut-tab-panel>
        <nut-tab-panel tab-title="Self-financed">
          <nut-infiniteloading @loadmore="onInfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="Loading" unload-more-txt="no more information" :threshold="200">
            <div class="goods-item">
              <div class="goods-item-list" v-for="(item, index) in list" :key="index" @click="goPage(item, 'goodsDetail')">
                <div class="list-img">
                  <img class="img-self" :src="item.scg_cover_image" />
                  <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
                </div>
                <div class="list-info">
                  <p class="info-title">{{item.en_name}}</p>
                  <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span>({{item.is_self === 1 ? item.tuition_last_fee_unit : item.self_tuition_fee_unit}})</p>
                  <p class="info-text">City: {{item.city}} {{item.province}}</p>
                  <p class="info-text">Degree: {{item.education}}</p>
                  <p class="info-text">Teaching language: {{item.language}}</p>
                  <p class="info-text"><span class="text-btn" >Apply</span></p>
                </div>
              </div>
            </div>
          </nut-infiniteloading>
        </nut-tab-panel>
        <nut-tab-panel tab-title="Universities">
          <nut-infiniteloading @loadmore="onInfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="Loading" unload-more-txt="no more information" :threshold="100">
            <div class="school-item">
              <div class="school-item-list" v-for="(item, index) in list" :key="index" @click="goPage(item, 'schoolDetail')">
                <img class="list-img" :src="item.logo" />
                <div class="list-info">
                  <p class="info-title">{{item.en_name}}</p>
                  <div class="info-lab">
                    <span class="lab-self" v-for="(info, idx) in item.labels" :key="idx">{{info}}</span>
                  </div>
                  <p class="info-text">City: {{item.city}}, {{item.province}}</p>
                </div>
              </div>
            </div>
          </nut-infiniteloading>
        </nut-tab-panel>
      </nut-tab>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'searchIndex',
  props: {},
  data () {
    return {
      isShow: true,
      labels: [],
      isLoading: false,
      isHasMore: true,
      page: 1,
      total_pages: 0,
      limit: 8,
      info: {
        keyword: '',
        label_id: 0,
        type: 1
      },
      keyword: '',
      list: [],
      navType: 0
    }
  },
  components: {},
  computed: {},
  created () {
    this.getLabels()
  },
  watch: {},
  methods: {
    back () {
      this.$router.go(-1)
    },
    goPage (item, name) {
      let params = {}
      let url = ''
      if (name === 'schoolDetail') {
        url = '/detail/school'
        params = {
          university: item.urlname
        }
      } else {
        url = '/detail/goods'
        params = {
          program: item.urlname,
          ProgramID: item.id
        }
      }
      this.$router.push({
        path: url,
        query: params
      })
    },
    // 获取热门推荐
    getLabels () {
      let that = this
      Api.get({
        url: 'labels/homeLabel',
        data: {
          type: 4,
        },
        success (res) {
          if (res.data) {
            that.labels = res.data
          }
        }
      })
    },
    // 热门标签选择
    clickLabels (item) {
      this.isShow = false
      this.page = 1
      this.list = []
      this.keyword = item.name
      this.info.label_id = item.id
      this.search()
    },
    // 标签切换
    tabSwitch (index) {
      this.page = 1
      this.list = []
      this.navType = Number(index)
      this.search()
    },
    // 下拉加载
    onInfinite () {
      this.page++
      if (this.page > this.total_pages) {
        this.isHasMore = false
        return false
      }
      this.search()
    },
    submitSearch () {
      this.list = []
      this.page = 1
      this.info.label_id = 0
      this.info.keyword = this.keyword
      this.search()
    },
    // 搜索
    search () {
      this.isShow = false
      if (this.navType === 0) {
        this.info.type = 1
        this.getGoods()
      } else if(this.navType === 1) {
        this.info.type = 2
        this.getGoods()
      } else {
        this.getSchool()
      }
    },
    // 获取学校搜索列表
    getSchool () {
      let that = this
      this.isLoading = true
      Api.get({
        url: 'schools',
        data: {
          keyword: this.keyword,
          limit: this.limit,
          page: this.page
        },
        success (res) {
          if (res.code === 200) {
            if (res.data.length <= 0) {
              that.list = []
            }
            for (let i in res.data) {
              that.list.push(res.data[i])
            }
            that.isLoading = false
            that.total_pages = res.total_pages
          }
        }
      })
    },
    // 获取商品列表
    getGoods () {
      let that = this
      this.isLoading = true
      let data = {
        ...this.info,
        page: this.page,
        limit: this.limit
      }
      Api.get({
        url: 'products',
        data: data,
        success (res) {
          if (res.code === 200 && res.data) {
            for (let i in res.data) {
              that.list.push(res.data[i])
            }
            that.isLoading = false
            that.total_pages = res.total_pages
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.search-index>>>.nut-tab{padding:0;border:none;}
.search-index>>>.nut-tab-active a{color:#7f51cb;}
.search-index>>>.nav-bar{background-color:#7f51cb;}
.search-index>>>.nut-tab-link{text-align:center;font-size:14px;}
.search-index>>>.nut-tab-item{height:auto;}
.search-index{background-color:#fff;}
.search-index .search-top{padding:8px 12px;}
.search-index .search-top .top-search-self{padding-top:12px;}
.search-index .search-content{padding:12px 15px;}
.search-index .search-content .content-hot .hot-title{font-size:15px;color:#333;font-weight:bold;line-height:44px;}
.search-index .search-content .content-hot .hot-item{overflow:hidden;padding-top:10px;}
.search-index .search-content .content-hot .hot-item .hot-tag{display:inline-block;position:relative;margin-right:15px;margin-bottom:15px;padding:8px 12px;background-color:#b0332fa6;font-size:12px;border-radius:6px;color:#7f51cb;}
.search-index .search-content .content-hot .hot-item .hot-tag.icon::before{content:'';position:absolute;display: block;top:-7px;right:-7px;width:15px;height:15px;background:url('~@/assets/images/search-hot-icon.png') center no-repeat;background-size:15px;}
.goods-item{padding:0;}
</style>
