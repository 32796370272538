<template>
  <!-- 企业（公司）认证 -->
  <div class="personal-index wrapper">
    <div class="personal-centent public-item">
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Company name</p>
        <nut-textinput  v-model="companyInfo.company_name" placeholder="Please enter your company name" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title">Company website</p>
        <nut-textinput  v-model="companyInfo.website" placeholder="Please enter your company website" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Company Phone</p>
        <nut-textinput  v-model="companyInfo.phone_number" placeholder="Please enter your company phone no." :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Company address</p>
        <nut-textinput  v-model="companyInfo.address" placeholder="Please enter your company address" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Full name of legal person</p>
        <nut-textinput  v-model="companyInfo.legal_representative" placeholder="Please enter the full name of your company’s legal person" :clearBtn="true" :disabled="false" :has-border="false"/>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Upload passport of registrant</p>
        <div class="list-imgs">
          <nut-uploader
            name="file"
            url="https://center.istudyedu.com/api/agents/upload"
            :headers="importHeaders"
            :isPreview="true"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
            @success="registrantSuccess"
            @preview="registrantPreview"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <img v-if="registrant_passport !== ''" class="upload-img" :src="registrant_passport" />
            <span v-else class="upload-btn">+</span>
          </nut-uploader> 
        </div>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Upload photo of registrant holding passport</p>
        <div class="list-imgs">
          <nut-uploader
            name="file"
            url="https://center.istudyedu.com/api/agents/upload"
            :headers="importHeaders"
            :isPreview="true"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
            @success="handSuccess"
            @preview="handPreview"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <img v-if="passport_hand !== ''" class="upload-img" :src="passport_hand" />
            <span v-else class="upload-btn">+</span>
          </nut-uploader> 
        </div>
      </div>
      <div class="item-list">
        <p class="list-title"><span class="title-icon">*</span>Business license</p>
        <div class="list-imgs">
          <nut-uploader
            name="file"
            url="https://center.istudyedu.com/api/agents/upload"
            :headers="importHeaders"
            :isPreview="true"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
            @success="licenseSuccess"
            @preview="licensePreview"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <img v-if="license_attachment !== ''" class="upload-img" :src="license_attachment" />
            <span v-else class="upload-btn">+</span>
          </nut-uploader> 
        </div>
      </div>
      <div class="item-list">
        <p class="list-title">Business card</p>
        <div class="list-imgs">
          <nut-uploader
            name="file"
            url="https://center.istudyedu.com/api/agents/upload"
            :headers="importHeaders"
            :isPreview="true"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
            @success="cardSuccess"
            @preview="cardPreview"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <img v-if="card_attachment !== ''" class="upload-img" :src="card_attachment" />
            <span v-else class="upload-btn">+</span>
          </nut-uploader> 
        </div>
      </div>
      <div class="item-list">
        <p class="list-title">Company logo</p>
        <div class="list-imgs">
          <nut-uploader
            name="file"
            url="https://center.istudyedu.com/api/agents/upload"
            :headers="importHeaders"
            :isPreview="true"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
            @success="logoSuccess"
            @preview="logoPreview"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <img v-if="logo !== ''" class="upload-img" :src="logo" />
            <span v-else class="upload-btn">+</span>
          </nut-uploader> 
        </div>
      </div>
    </div>
    <div class="personal-bottom">
      <div class="bottom-box">
        <div class="box-checkbox">
          <nut-checkbox v-model="checked" :animation="false" size="small">I confirm the information above is real and correct.</nut-checkbox>
        </div>
        <div class="bottom-btn" @click="companyVerified">Submit</div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'personalIndex',
  props: {},
  data () {
    return {
      checked: true,
      statu: '',
      role: 0,
      importHeaders: {
        Authorization: 'bearer ' + window.localStorage.getItem('token')
      },
      companyInfo: {
        company_name: '',
        legal_representative: '',
        phone_number: '',
        address: '',
        website: '',
        license_attachment: '',
        passport_hand: '',
        registrant_passport: '',
        logo: '',
        card_attachment: ''
      },
      card_attachment: '',
      passport_hand: '',
      registrant_passport: '',
      logo: '',
      license_attachment: ''
    }
  },
  components: {},
  computed: {},
  created () {},
  watch: {},
  methods: {
    getInfo () {
      let that = this
      Api.post({
        url: "users/info?include=agent",
        success (res) {
          if (res.data && res.code === 200) {
            let datas = res.data.agent.data
            that.statu = Number(datas.auth_state)
            that.role = Number(res.data.role)
            if (datas.role === 2) {
              that.companyInfo.company_name = datas.company_name
              that.companyInfo.address = datas.address
              that.companyInfo.phone_number = datas.phone_number
              that.companyInfo.website = datas.website
              that.companyInfo.legal_representative = datas.legal_representative
              that.companyInfo.card_attachment = datas.card_attachment ? datas.card_attachment.substring(datas.card_attachment.indexOf('agent')) : ''
              that.companyInfo.passport_hand = datas.passport_hand ? datas.passport_hand.substring(datas.passport_hand.indexOf('agent')) : ''
              that.companyInfo.registrant_passport = datas.registrant_passport ? datas.registrant_passport.substring(datas.registrant_passport.indexOf('agent')) : ''
              that.companyInfo.logo = datas.logo ?  datas.logo.substring(datas.logo.indexOf('agent')) : ''
              that.companyInfo.license_attachment = datas.license_attachment ? datas.license_attachment.substring(datas.license_attachment.indexOf('agent')) : ''
              that.card_attachment = datas.card_attachment
              that.passport_hand = datas.passport_hand
              that.registrant_passport = datas.registrant_passport
              that.logo = datas.logo
              that.license_attachment = datas.license_attachment
            }
          }
        }
      })
    },
    permitFail () {
      this.$toast.fail('upload failed')
    },
    // Passport of registrant上传成功
    registrantSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        this.$toast.success('Uploaded successfully')
        this.companyInfo.registrant_passport = data.data.url ? data.data.url : ''
      }
    },
    registrantPreview (e) {
      this.registrant_passport = e
    },
    handSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        this.$toast.success('Uploaded successfully')
        this.companyInfo.passport_hand = data.data.url ? data.data.url : ''
      }
    },
    handPreview  (e) {
      this.passport_hand = e
    },
    licenseSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        this.$toast.success('Uploaded successfully')
        this.companyInfo.license_attachment = data.data.url ? data.data.url : ''
      }
    },
    licensePreview (e) {
      this.license_attachment = e
    },
    cardSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        this.$toast.success('Uploaded successfully')
        this.companyInfo.card_attachment = data.data.url ? data.data.url : ''
      }
    },
    cardPreview (e) {
      this.card_attachment = e
    },
    logoSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        this.$toast.success('Uploaded successfully')
        this.companyInfo.logo = data.data.url ? data.data.url : ''
      }
    },
    logoPreview (e) {
      this.logo = e
    },
    companyInfoVerify () {
      if (this.companyInfo.company_name === '') {
        this.$toast.warn("Company name can't be empty, please enter it.")
        return false
      }
      if (this.companyInfo.phone_number === '') {
        this.$toast.warn("Company phone can't be empty, please enter it.")
        return false
      }
      if (this.companyInfo.legal_representative === '') {
        this.$toast.warn("Full name of legal person can't be empty, please enter it.")
        return false
      }
      if (this.companyInfo.address === '') {
        this.$toast.warn("Company address can't be empty, please enter it.")
        return false
      }
      if (this.companyInfo.license_attachment === '') {
        this.$toast.warn('Business license is required, please upload it.')
        return false
      }
      if (this.companyInfo.passport_hand === '') {
        this.$toast.warn('Registrant with passport held in hand is required, please upload it.')
        return false
      }
      if (this.companyInfo.registrant_passport === '') {
        this.$toast.warn('Registrant with passport held in hand is required, please upload it.')
        return false
      }
      return true
    },
    companyVerified () {
      let that = this
      if (!this.companyInfoVerify()) {
        return false
      }
      Api.post({
        url: 'agents/CompanyVerified',
        data: this.companyInfo,
        success (res) {
          if (res.code === 200 && res.data) {
            that.$toast.success(res.data.msg)
            that.$router.push({path: '/list/goodsList'})
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.personal-index>>>.nut-checkbox input:checked{background-color:#b0332f;border-color:#b0332f;}
.personal-centent{background-color:#fff;padding:0 10px;box-sizing:border-box;}
.personal-bottom{height:100px;}
.box-checkbox{padding-top:15px;margin:0 auto;width:300px;}
.personal-bottom .bottom-box{position:fixed;bottom:0;height:100px;max-width:750px;width:100%;margin:0 auto;background-color:#fff;}
.personal-bottom .bottom-box .bottom-btn{margin:10px 20px;height:45px;line-height:45px;text-align:center;background-color:#b0332f;color:#fff;border-radius:10px;}
.personal-bottom .bottom-text{text-align: center;margin-bottom:20px;color: #564363;font-size:14px;}
</style>
