<template>
  <div class="order-index wrapper">
    <nut-searchbar class="order-search" v-model="keywords" placeText="Student name/Application ID / Program ID" @submit="getOrderList"></nut-searchbar>
    <div class="order-nav">
      <div class="nav-list" :class="{'active': item.active}" v-for="(item, index) in nav" @click="navClick(index)" :key="index">{{item.name}}</div>
    </div>
    <div class="order-applications">
      <div class="applications-list" :class="{'active': item.active}" v-for="(item, index) in applicationStatus" @click="handleCommand(index)" :key="index">{{item.name}}</div>
    </div>
    <ul class="order-item">
      <li class="item-list" v-for="(item, index) in list" :key="index">
        <div class="list-top">
          <div class="top-state">
            <span class="text-review" v-if="item.apply_pay_state === 1">To be reviewed</span> 
            <span class="text-over" v-if="item.apply_pay_state === 2">Paid</span>
            <span class="text-error" v-if="item.apply_pay_state === 3">Payment failed</span>
          </div>
          <div class="top-info">
            <p class="info-title">{{item.student}}</p>
            <p class="info-text">Order number: {{item.order_sn}}</p>
          </div>
        </div>
        <div class="list-info" v-for="(info, idx) in item.applications.data" :key="idx">
          <div class="info-self">
            <img class="self-img" :src="info.cover_image" />
            <div class="self-box">
              <p class="box-title">{{info.en_name}} <span class="name-issp" v-if="info.issp">ISSP</span></p>
              <p class="box-text">Program ID: {{info.id}}</p>
              <p class="box-text">Application ID: {{info.application_sn}}</p>
              <p class="box-text" v-for="(list, idxs) in info.school_fees" :key="idxs">{{list.fee_name}}: ¥{{list.amount}}</p>
            </div>
          </div>
          <div class="info-btns">
            <span class="btn-self" >View details</span>
            <span class="btn-self" :class="{'disabled': info.showUploadBtn === 0}" @click="goPage(info)">Upload files</span>
          </div>
        </div>
        <div class="list-btn">
          <span class="btn-self" v-if="item.apply_pay_state === 0 && item.state !== 0" @click="pay">Pay Now</span>
          <span class="btn-text">Total application fee: <span class="text-num">&yen;{{item.apply_total}}</span></span>
        </div>
      </li>
    </ul>
    <footerNav :type="'order'"></footerNav>
  </div>
</template>
<script>
import Api from '@/api/api.js'
import footerNav from "@/components/public/footerNav.vue"
export default {
  name: 'orderIndex',
  props: {},
  data () {
    return {
      keywords: '',
      dropDownText: 'All',
      applicationState: 99,
      orderState: 99,
      list: [],
      nav: [
        {
          name: 'All',
          type: 99,
          active: true
        }
      ],
      applicationStatus: [
        {
          name: 'All',
          type: 99,
          active: true
        }
      ]
    }
  },
  components: {
    footerNav
  },
  computed: {},
  created () {
    let info = JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')) : {}
    if (Object.keys(info).length <= 0 ) {
      this.$router.push({ path: '/login', })
      return false
    }
    this.getOrderList()
    this.getOrderState()
    this.getApplicationsState()
  },
  watch: {},
  methods: {
  // 选择申请单状态
    handleCommand (index) {
      for (let i in this.applicationStatus) {
        this.applicationStatus[i].active = false
      }
      this.applicationStatus[index].active = true
      this.applicationState = this.applicationStatus[index].type
      this.getOrderList()
    },
    // 订单状态选择
    navClick (index) {
      for (let i in this.nav) {
        this.nav[i].active = false
      }
      this.nav[index].active = true
      this.orderState = this.nav[index].type
      this.getOrderList()
    },
    // 获取订单列表
    getOrderList () {
      let that = this
      Api.get({
        url: 'orders?include=applications',
        data: {
          keywords: this.keywords,
          order_state: this.orderState,
          application_state: this.applicationState
        },
        success (res) {
          if (res.data && res.code === 200) {
            that.list = res.data
          }
        }
      })
    },
    // 获取订单状态
    getOrderState () {
      let that = this
      Api.get({
        url: 'orders/state',
        data: {},
        success (res) {
          if (res.data && res.code === 200) {
            for (let i in res.data.state) {
              let data = {
                name: res.data.state[i],
                type: i,
                active: false
              }
              if (Number(i) === Number(that.orderState)) {
                data.active = true
              }
              that.nav.push(data)
            }
          }
        }
      })
    },
    // 获取申请单状态
    getApplicationsState () {
      let that = this
      Api.get({
        url: 'applications/state',
        data: {},
        success (res) {
          if (res.data && res.code === 200) {
            let datas = res.data.state
            for (let i in datas) {
              let data = {
                name: datas[i],
                type: i,
                active: false
              }
              if (Number(i) === Number(that.applicationState)) {
                data.active = true
              }
              that.applicationStatus.push(data)
            }
          }
        }
      })
    },
    // 跳转页面
    goPage (info) {
      if (info.showUploadBtn === 0) {
        return false
      }
      this.$router.push({
        path: '/applications/detail',
        query : {
          id: info.id
        }
      })
    },
    pay () {
      this.$toast.text('The mobile client doesn’t support the payment now. Please contact your consultant for payment. ')
    }
  }
}
</script>

<style scoped>
.order-search{padding:10px 20px;background-color:#fff;}
.order-nav{overflow-x:scroll;overflow-y:hidden;padding:10px;width:100%;white-space:nowrap;box-sizing:border-box;background-color:#fff;}
.order-nav::-webkit-scrollbar{display:none;}
.order-nav .nav-list{padding:0 10px 5px;display:inline-block;font-size:14px;line-height:28px;}
.order-nav .nav-list.active{color:#b0332f;position:relative;}
.order-nav .nav-list.active::before{content:'';position: absolute;display:block;width:20px;height:4px;border-radius:4px;left:50%;bottom:0;background-color:#b0332f;transform: translate(-50%,0);}
.order-applications{overflow-x:scroll;overflow-y:hidden;padding:10px;width:100%;white-space:nowrap;box-sizing:border-box;}
.order-applications::-webkit-scrollbar{display:none;}
.order-applications .applications-list{display:inline-block;font-size:14px;line-height:28px;padding:0 10px;}
.order-applications .applications-list.active{color:#b0332f;border:1px solid #b0332f;border-radius:20px;}
.order-item{padding:0 12px;}
.order-item .item-list{margin-bottom:12px;background-color:#fff;border-radius:10px;padding:12px;}
.order-item .item-list .list-top{overflow:hidden;margin-bottom:10px;}
.order-item .item-list .list-top .top-state{float:right;font-size:12px;}
.order-item .item-list .list-top .top-info{margin-right:100px;}
.order-item .item-list .list-top .top-info .info-title{font-size:15px;font-weight:600;line-height:19px;}
.order-item .item-list .list-top .top-info .info-text{font-size:12px;color:#999;}
.order-item .item-list .list-info{background-color:#f5f5f5;margin-bottom:12px;border-radius:8px;}
.order-item .item-list .list-info .info-self{overflow:hidden;padding:12px;}
.order-item .item-list .list-info .info-self .self-img{display: block;float:left;width:100px;height:100px;}
.order-item .item-list .list-info .info-self .self-box{margin-left:120px;}
.order-item .item-list .list-info .info-self .self-box .box-title{font-size:13px;color:#333;line-height:18px;margin-bottom:10px;}
.order-item .item-list .list-info .info-self .self-box .box-text{font-size:12px;color:#666;margin-bottom:5px;}
.order-item .item-list .list-info .info-btns{border-top:1px solid #e6e6e6;padding:12px;overflow:hidden;}
.order-item .item-list .list-info .info-btns .btn-self{float:right;display:block;font-size:12px;padding:0 10px;line-height:24px;border:1px solid #333;border-radius:4px;margin-left:12px;}
.order-item .item-list .list-info .info-btns .btn-self.disabled{background-color:#e6e6e6;border:none;}
.order-item .item-list .list-btn{overflow:hidden;padding:10px 0;}
.order-item .item-list .list-btn .btn-text{float:right;font-size:14px;color:#333;line-height:26px;}
.order-item .item-list .list-btn .btn-self{float:right;margin-left:20px;display:block;font-size:14px;color:#fff;background-color:#b0332f;padding:5px 10px;border-radius:5px;}
</style>
